@import "../../../styles/_mixines";

.dashboard {
  position: relative;
  background-color: #ffffff;

  &__note {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
  }
}

.dashboard-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;

  &__note {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #7b7b7b;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
  }
}

.dashboard-column {
  flex-basis: 47%;

  &--main {
    flex-basis: 745px;
  }

  &--secondary {
    flex-basis: 310px;
  }

  &--map {
    flex-basis: 53%;
  }

  &--description {
    flex-basis: 45%;
    margin-top: 15px;
  }

  &--detail {
    flex-basis: 50%;
    margin-top: 15px;
  }
}

.dashboard-list {
  list-style: none outside none;
  padding: 0;
  margin-top: 5px;

  &-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 8px;
    padding-bottom: 7px;

    &--main {
      background-color: #b2c1d6;

      .dashboard-list-item__name {
        font: 700 16px/20px $font-Comfortaa;
        padding-left: 15px;
      }
    }
  }
}

.dashboard-box {
  background-color: #5fc5ff;
  display: flex;
  box-sizing: border-box;
  padding: 13px 20px;
  color: #ffffff;
  align-items: center;

  &--center {
    justify-content: center;
  }

  &--first {
    margin-top: 45px;
  }

  &--last {
    margin-top: 15px;
  }

  &-value {
    display: block;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    height: 48px;
    box-sizing: border-box;
    padding-right: 10px;

    &__percent {
      font-size: 36px;
    }
  }

  &-info {
    display: block;
    margin-left: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  &__name {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  &__description {
    display: block;
    margin-top: 5px;
  }
}

.dashboard-case {
  border-top: 1px solid #b2c1d6;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-weight: 400;

  &--first {
    margin-top: 50px;
  }

  &__caption {
    color: #000000;
    font-size: 22px;
    line-height: 28px;
  }

  &__description {
    color: #7b7b7b;
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
  }
}

.dashboard-block {
  border-top: 1px solid #e8e8e8;
  padding-top: 15px;
  padding-bottom: 15px;

  &:first-child {
    border-top: none;
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
  }

  &__link {
    display: inline-block;
  }

  &__value {
    font-weight: 300;
    font-size: 18px;
    margin-top: 5px;

    .dashboard-block__link {
      font-size: 16px;
    }
  }

  &__map {
    height: 235px;

    .gmnoprint,
		[href^="https://maps.google.com/maps"]
    {
      display: none !important;
    }
  }
}

.dashboard-caption {
  font-family: $font-Comfortaa;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 6px;
  text-transform: uppercase;
  border-bottom: 2px solid #6f6f6f;
  margin: 0;
}

.dashboard-overview {
  margin-top: 20px;
}

.dashboard-tooltip {
  position: absolute;
  left: 0;
  bottom: 100%;
  transform: translate3d(15px, 0, 0);
  padding: 4px 20px;
  color: #ffffff;
  background-color: #3c4450;
  font-size: 12px;
  line-height: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease 0s;
  font-weight: normal;
  text-align: center;
  text-transform: none;
  min-width: 250px;
  margin-bottom: 5px;

  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0;
    border-color: #3c4450 transparent transparent;
    transform: translateX(-50%);
  }
}

.dashboard-list-header {
  display: flex;
  font-family: $font-Comfortaa;
  border-bottom: 1px solid #b2c1d6;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;

  .header-name {
    line-height: 20px;
    font-weight: 700;
    font-size: 16px;
  }

  .header-caption {
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
    color: #3e3e3e;

    &--tooltip-holder {
      position: relative;

      &:hover {
        color: #000000;

        .dashboard-tooltip {
          visibility: visible;
          transform: translate3d(0, 0, 0);
          opacity: 1;
          z-index: 10;
          transition: all 0.5s ease 0s;
        }
      }
    }
  }
}

.dashboard-table {
  &__name {
    text-align: left;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 8px;
    padding-bottom: 7px;
  }

  &__value {
    text-align: right;
    padding-right: 10px;
    border-bottom: 1px solid #e8e8e8;
    padding-top: 8px;
    padding-bottom: 6px;
  }
}

.dashboard-map {
  display: flex;
  margin-top: 250px;
  max-width: 540px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 60px;
  box-sizing: border-box;

  &__caption {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
  }

  &__screen {
    position: relative;
    margin-top: 30px;
    overflow: hidden;
  }

  &-marker {
    display: inline-block;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  &__svg {
    position: absolute;
    left: 50%;
    top: 0;
    width: 350px;
    transform: translateX(-50%);
    height: 222px;

    .south,
    .midwest,
    .northeast,
    .west {
      transition: all 0.3s ease 0s;
    }
  }

  &-item {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    font: 700 16px/20px $font-Comfortaa;
    color: #cfcfcf;
    text-transform: uppercase;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;

    &--west {
      .dashboard-map-marker {
        background-color: #3b9ce9;
      }

      &:hover {
        color: #3b9ce9;

        ~ .dashboard-map__svg {
          .west {
            transform: scale(1.04);
            transform-origin: center center;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    &--midwest {
      .dashboard-map-marker {
        background-color: #8cd14f;
      }

      &:hover {
        color: #8cd14f;

        ~ .dashboard-map__svg {
          .midwest {
            transform: scale(1.04);
            transform-origin: center center;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    &--northeast {
      .dashboard-map-marker {
        background-color: #f66b4c;
      }

      &:hover {
        color: #f66b4c;

        ~ .dashboard-map__svg {
          .northeast {
            transform: scale(1.04);
            transform-origin: center center;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    &--south {
      .dashboard-map-marker {
        background-color: #cf728d;
      }

      &:hover {
        color: #cf728d;

        ~ .dashboard-map__svg {
          .south {
            transform: scale(1.04);
            transform-origin: center center;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    &__caption {
      padding-left: 10px;
    }

    &__value {
      padding-left: 5px;
      color: #000;
    }
  }
}

.location-address {
  margin-top: 10px;
  font-size: 14px;

  &__name {
    font-size: 16px;
    font-weight: bold;
  }

  &__description {
    margin-top: 10px;
  }

  &__phone {
    margin-top: 2px;
  }

  &__link {
    display: inline-block;
    margin-top: 40px;
  }
}

.location-desctibe {
  margin-top: 10px;
  font-size: 14px;

  &-list {
    margin-top: 5px;
  }

  &-item {
    &__label {
      color: #a7a7a7;
    }
  }
}

.location-history {
  margin-top: 10px;
  font-size: 14px;

  &-list {
    margin-top: 5px;
  }

  &-item {
    &__label {
      color: #a7a7a7;
    }
  }
}

.location-social {
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;

  &__link {
    display: block;
    height: 35px;
    width: 35px;
    margin-right: 15px;
    transform: rotate(0deg);
    transition: all 0.3s ease 0s;

    &:hover {
      transform: rotate(360deg);
      transition: all 0.3s ease 0s;
    }
  }

  .icon {
    fill: #000000;
    height: 35px;
    width: 35px;
  }
}

.location-info {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 15px;

  &__caption {
    color: #a7a7a7;
    padding-right: 7px;
  }
}
