@use "@datassential/platform-ui-lib/lib/style/constants" as *;
@use "@datassential/platform-ui-lib/lib/style/utils" as *;

* {
  outline: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-Comfortaa;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizelegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-width: 1200px;
}

a {
  color: #209fe7;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.hidden-layer {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.main-background {
  background: #e5e5e5;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.snapshot-only {
  display: none;
}

[snapshot] {
  &.snip-process {
    .snapshot-disable {
      display: none;
    }

    .snapshot-only {
      display: inherit;
    }
  }
}

.window {
  min-height: 100%;
  height: 100%;
  transition: filter 0.5s linear 0s;

  &--scroll {
    overflow-y: scroll;
  }
}

.container {
  max-width: $width-site-max;
  min-width: $width-site-min;
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  box-sizing: content-box;

  &--content {
    padding-top: 25px;
  }

  &--wide {
    max-width: 99%;
  }
}

.container-sidebar {
  display: flex;
  position: relative;
  padding-left: 0;
  margin-left: 0;
  min-height: calc(100vh - calc(#{$platform-header-height} + #{$platform-navigation-height}));

  &-side {
    position: relative;
    background-color: #424452;
    flex: 0 0 310px;
    box-sizing: border-box;
    padding-top: 60px;
    z-index: 1;
    padding-left: 20px;
    min-height: 100%;

    &__separator {
      position: relative;
      padding-left: 30px;

      &:after {
        content: "or";
        height: 68px;
        width: 68px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        color: #ffffff;
        top: 50%;
        transform: translate(50%, -50%);
        right: 0;
        background-image: linear-gradient(135deg, #B90081, #D938CF);
      }
    }
  }

  &-main {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding-top: 60px;
    padding-left: 50px;
  }

  &-content {
    margin-top: 20px;
    padding-right: 30px;
  }
}

.main-caption {
  font-family: $font-Comfortaa;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;

  &--primary {
    color: #ffffff;
  }
}
