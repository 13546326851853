.toggle-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 47.5%;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 5px;
    padding-top: 5px;
    overflow: hidden;
  }

  &__empty {
    font-size: 12px;
    color: #898989;
    text-align: center;
  }

  &-bottom {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
  }

  &__button-more {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;

    .icon {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 32px;
      width: 32px;
      fill: #06c8ff;
      transition: all 0.4s ease 0.1s;
      transform: rotate(0deg);

      &--up-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .icon-delete {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 20px;
    cursor: pointer;
    fill: none;

    &:hover {
      stroke: #f53b44;
    }
  }
}
