.dropdown {
  position: relative;

  &-display {
    position: relative;
    cursor: pointer;
    white-space: normal;
    outline: 0;
    height: 36px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 17px;
    box-sizing: border-box;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top-color: #ffffff;
      border-bottom: none;
    }

    &__caption {
      text-align: left;
      user-select: none;

      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px dashed #ffffff;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 15px;
      color: #ffffff;
    }
  }

  &-menu {
    box-sizing: border-box;
    backface-visibility: hidden;
    cursor: auto;
    position: absolute;
    outline: 0;
    top: 100%;
    min-width: 150px;
    margin: 0;
    padding-bottom: 5px;
    background: #ffffff;
    border-radius: 6px;
    border-color: rgba(34, 36, 38, 0.35);
    border-style: solid;
    border-width: 0 1px 1px;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    right: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -4px;
      right: 20%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 4px;
      border-color: transparent transparent #fff;
    }

    &-noresults {
      color: #4a4a4a;
      font-size: 12px;
      text-align: center;
    }
  }

  &-list {
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-item {
    padding: 4px 10px 2px;
    cursor: pointer;
    font-size: 12px;
    color: #4a4a4a;
    line-height: 18px;

    &:hover {
      background-color: #eaeaea;
    }

    &.is-selected {
      background-color: #22a7f1;
      color: #ffffff;
      pointer-events: none;
    }
  }

  &-group {
    &-caption {
      position: relative;
      background-color: #ffffff;
      line-height: 13px;
      color: #000000;
      font-size: 13px;
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 2px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
        bottom: 7px;
        left: 0;
        z-index: 1;
      }

      &__name {
        position: relative;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        text-transform: uppercase;
        background-color: #ffffff;
        z-index: 2;
      }
    }

    .dropdown-item {
      padding-left: 20px;
    }
  }

  &.is-open {
    .dropdown-display__caption {
      border-bottom: 1px solid #fff;
    }
  }
}

.dropdown-label {
  white-space: nowrap;
  padding-right: 10px;
  text-transform: uppercase;
  padding-top: 2px;
  font-size: 12px;
}

.dropdown-respondent {
  min-width: 100px;

  .dropdown-menu {
    width: 150px;
  }
}

.dropdown-frequency {
  display: inline-block;

  .dropdown-display {
    padding-right: 0;

    &:before {
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 195px;
  }
}

.dropdown-among {
  margin-top: 4px;
}

/*
.dropdown{
	width: 100%;
	position: relative;

	&.is-open{

		.dropdown-display{
			border-color: rgba(34,36,38,.35);
			border-bottom: 1px solid transparent;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}


	&-display{
		position: relative;
		cursor: pointer;
		white-space: normal;
		outline: 0;
		min-height: 38px;
		background: #ffffff;
		display: flex;
		border: 1px solid #D9D9D9;
		border-radius: 6px;
		align-items: center;
		justify-content: flex-start;
		padding-left: 10px;
		padding-right: 20px;
		box-sizing: border-box;

		&:before{
			content: "";
			position: absolute;
			top: 0;
			right: 10px;
			bottom: 0;
			margin: auto;
			width: 0;
			height: 0;
			border: 4px solid transparent;
			border-top-color: #3A414C;
			border-bottom: none;
		}

		&:hover{
			border-color: rgba(34,36,38,.35);
		}
 
		&__caption{
			color: #4A4A4A;
			font-size: 12px;
			padding-top: 4px; 
			text-align: left;
			-webkit-user-select: none;
			user-select: none;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&-menu{
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;

		backface-visibility: hidden;
		overflow-scrolling: touch;

		cursor: auto;
		position: absolute;
		outline: 0;
		top: calc(100% - 1px);
		min-width: 100%;
		margin: 0;
		padding: 0 0 5px 0;
		background: #ffffff;
		text-align: left;
 
		border-radius: 0px 0px 6px 6px;

		border-color: rgba(34,36,38,.35);
		border-style: solid;
		border-width: 0px 1px 1px 1px;

		z-index: 10;

		&-noresults {
			color: #4A4A4A;
			font-size: 12px;
			text-align: center;
		}
	}

	&-group{

		&-caption{

			position: relative;
			background-color: #ffffff;
			line-height: 13px;
			color: #000000;
			font-size: 13px;
			font-weight: bold;
			padding-top: 4px;
			padding-bottom: 2px;

			&:before{
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 1px;
				background-color: #D9D9D9;
				bottom: 7px;
				left: 0;
				z-index: 1;
			}

			&__name{
				position: relative;
				display: inline-block;
				padding-left: 10px;
				padding-right: 10px;
				text-transform: uppercase;
				background-color: #ffffff;
				z-index: 2;
			}
		}

		.dropdown-item{
			padding-left: 20px; 
		}
	}


	&-item{
		padding: 4px 10px 2px 10px;
		cursor: pointer;
		font-size: 12px;
		color: #4A4A4A;
		line-height: 18px;

		&:hover{
			background-color: #eaeaea;
		}

		&.is-selected{
			background-color: #22a7f1;
			color: #ffffff;
			pointer-events: none;
		}
	}
}

.dropdown--segment{
	width: 150px;
}

.dropdown--columns{
	width: 270px;
}

.dropdown--among{
	width: 240px;
}

 

*/
