.histogram-ranking {
  position: relative;
  padding-right: 20px;
  margin-right: -20px;

  &--scroll {
    max-height: 330px;
  }

  &__gauge-linear {
    &:first-child {
      margin-top: 0;
    }
  }

  &__label {
    flex-basis: 280px;
    text-align: right;
  }
}
