$gauge-circle-dimension: 130px;

.gauge-circle {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: $gauge-circle-dimension;
  height: $gauge-circle-dimension;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #9aa6b8 inset;

  &:before {
    content: "";
    display: block;
    height: 24px;
    position: absolute;
    left: calc(50% - 1px);
    width: 2px;
    top: -10px;
    background-color: #cccccc;
    transition: all 0.3s ease 0s;
    overflow: visible;
  }

  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #000000;
    user-select: none;
  }

  &-caption {
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translateX(-50%);
  }

  &-value {
    margin-top: 18px;
    font: normal 44px/44px $font-FjallaOne;
    vertical-align: bottom;

    &__sufix {
      font-size: 24px;
    }

    transform: scale(1);
    transition: transform 0.4s ease 0.2s;
  }

  &-pointer {
    position: absolute;
    left: calc(50% - 6px);
    width: 12px;
    height: 30px;
    background-color: transparent;
    top: 0;
    transform: rotate(0);
    transition: 0.6s ease 0.1s;
    transform-origin: 6px $gauge-circle-dimension * 0.5;
    backface-visibility: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 6px 0;
      border-color: #3c4450 transparent transparent;
    }
  }

  &:nth-child(2) {
    .gauge-circle-pointer {
      transition: 0.6s ease 0.3s;
    }
  }

  &:nth-child(3) {
    .gauge-circle-pointer {
      transition: 0.6s ease 0.6s;
    }
  }

  &:hover {
    &:before {
      visibility: hidden;
      height: 0;
      opacity: 0;
    }

    .gauge-circle-value {
      transform: scale(1.1);
      transition: transform 0.4s ease 0.2s;
    }
  }
}

.gauge-circle-info {
  position: absolute;
  z-index: 10;
  font-size: 11px;
  color: #000000;
  line-height: 14px;
  left: 50%;
  bottom: 44px;
  transform: translate(-50%, -50%);
  width: 160px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: none;

  &:before {
    content: "";
    display: block;
    background: #22a7f1;
    width: 1px;
    height: 25px;
    position: absolute;
    left: 50%;
    bottom: -34px;
  }

  &__name {
    display: block;
    color: #9aa6b8;
  }

  &--left {
    transform: translate(-170px, -90px);

    .gauge-circle:hover & {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.4s ease 0s;
    }

    &:before {
      transform: translate(35px, 10px) rotate(130deg);
    }
  }

  &--right {
    transform: translate(7px, -90px);

    .gauge-circle:hover & {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.4s ease 0.3s;
    }

    &:before {
      transform: translate(-35px, 10px) rotate(-130deg);
    }
  }

  &--bottom {
    transform: translate(-50%, 85px);

    .gauge-circle:hover & {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.4s ease 0.6s;
    }

    &:before {
      transform: translate(0, -62px);
    }
  }
}

.gauge-linear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  font-family: $font-Comfortaa;

  &-label {
    flex: 0 0 70px;
    text-align: left;
    box-sizing: border-box;
  }

  &-meter {
    flex: 1 0 auto;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 100px;
    background-color: #f2f2f2;
  }

  &-progress {
    height: 11px;
    border-radius: 100px;
    background-color: #22a8f3;
  }

  &-value {
    flex: 0 0 30px;
    text-align: right;
    white-space: nowrap;
  }
}
