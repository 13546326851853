.content-loader {
  overflow: hidden;
  margin-top: 20px;

  &-block {
    height: 15px;
    margin-top: 10px;
    background-color: #d6d6d6;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: content-loader-animate;
      animation-timing-function: linear;
      background: linear-gradient(
        to right,
        #d6d6d6 8%,
        #c6c6c6 18%,
        #d6d6d6 33%
      );
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #e5e5e5;
      right: 0;
      top: 0;
      height: 100%;
      width: 0;
    }

    &--one {
      &:after {
        width: 15%;
      }
    }

    &--two {
      &:after {
        width: 1%;
      }
    }

    &--three {
      &:after {
        width: 28%;
      }
    }

    &--four {
      &:after {
        width: 10%;
      }
    }

    &--five {
      &:after {
        width: 60%;
      }
    }
  }
}

.table-loader {
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px 20px 13px;

  &-body {
    background-color: #d6d6d6;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: content-loader-animate;
      animation-timing-function: linear;
      background: linear-gradient(
        to right,
        #d6d6d6 8%,
        #d0cfcf 18%,
        #d6d6d6 33%
      );
    }
  }

  &-row {
    height: 7px;
    margin-top: 15px;
    background-color: #ffffff;
    position: relative;
  }

  &-col {
    width: 7px;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    top: 0;

    &--i1 {
      left: 20%;
    }

    &--i2 {
      left: 40%;
      top: 15px;
    }

    &--i3 {
      left: 60%;
    }

    &--i4 {
      left: 80%;
      top: 15px;
    }
  }
}

@keyframes content-loader-animate {
  0% {
    transform: translateX(-100%) translateZ(0);
  }

  100% {
    transform: translateX(100%) translateZ(0);
  }
}

.preloader-text {
  position: relative;

  &__placeholder {
    display: inline-block;

    &:before {
      content: attr(data-text);
      position: absolute;
      overflow: hidden;
      max-width: 0%;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.6);
      animation: loading 1s ease infinite 0s;
      height: 100px;
      left: 0;
      top: 0;
    }
  }
}

@keyframes loading {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}

//#EBF0F2
