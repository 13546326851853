@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  src: local("Comfortaa Light"), local("Comfortaa-Light"),
    url("fonts/comfortaa-v12-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/comfortaa-v12-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  src: local("Comfortaa Regular"), local("Comfortaa-Regular"),
    url("fonts/comfortaa-v12-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/comfortaa-v12-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  src: local("Comfortaa Bold"), local("Comfortaa-Bold"),
    url("fonts/comfortaa-v12-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/comfortaa-v12-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  src: local("Fjalla One"), local("FjallaOne-Regular"),
    url("fonts/fjalla-one-v5-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/fjalla-one-v5-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url("fonts/nunito-v9-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/nunito-v9-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
