@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/templates' as *;

.MainHeader {
    z-index: 90;

    &__item {
        @include header-nav-item;
    }

    &__countries-select {
        margin-left: rem(16px);
    }

    &__dropdown-item {
        cursor: pointer;
    }
}
