@import "../../../styles/_mixines";

.tooltip-hint {
  position: absolute;
  left: 140px;
  bottom: 8px;
  transform: translate3d(15px, 0, 0);
  padding: 5px 20px;
  color: #ffffff;
  background-color: #3c4450;
  font-size: 12px;
  line-height: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease 0s;
  font-weight: normal;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 65px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0;
    border-color: #3c4450 transparent transparent;
    transform: translateX(-50%);
  }
}

.sides-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &__title {
    position: relative;
    font: 700 18px/20px $font-Comfortaa;
    color: #000000;
    padding-bottom: 8px;
    border-bottom: 1px solid #d9d9d9;
  }

  &-colum {
    flex-basis: 48%;

    &:hover {
      .tooltip-hint {
        visibility: visible;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        z-index: 10;
        transition: all 0.5s ease 0s;
      }
    }
  }

  &-items {
    list-style: none outside none;
    margin: 5px 0 0;
    padding: 0;
    column-gap: 5px;
    margin-top: 5px;
    padding-left: 30px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;

    // &--columns{
    // 	column-count: 2;
    // }
  }

  &-item {
    font: 300 14px/28px $font-Comfortaa;
    position: relative;
    box-sizing: border-box;
    padding-left: 16px;
    margin-right: 16px;
    white-space: nowrap;

    .icon {
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }

    &__value {
      padding-left: 5px;
    }
  }

  &-empty {
    margin-top: 8px;
    color: #898989;
    text-align: center;
  }
}
