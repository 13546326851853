.autocomplete {
  width: 100%;
  position: relative;

  &-input {
    height: 36px;
    border: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 40px;
    border-radius: 6px;
    background: transparent;
  }

  &-display {
    position: relative;
    outline: 0;
    min-height: 38px;
    background: #ffffff;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #ffffff;
  }

  &-menu {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    cursor: auto;
    position: absolute;
    outline: 0;
    top: calc(100% - 1px);
    min-width: 100%;
    margin: 0;
    padding: 0 0 5px;
    background: #ffffff;
    text-align: left;
    border-radius: 0 0 6px 6px;
    border-color: rgba(34, 36, 38, 0.35);
    border-style: solid;
    border-width: 0 1px 1px;
    z-index: 10;
    max-height: 300px;

    &-noresults {
      color: #4a4a4a;
      font-size: 12px;
      text-align: center;
    }
  }

  &-item {
    padding: 4px 10px 2px;
    cursor: pointer;
    font-size: 12px;
    color: #4a4a4a;
    line-height: 18px;

    &:hover {
      background-color: #eaeaea;
    }

    &.is-selected {
      background-color: #22a7f1;
      color: #ffffff;
    }
  }

  &.is-open {
    .autocomplete-display {
      border-color: rgba(34, 36, 38, 0.35);
      border-bottom: 1px solid transparent;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
