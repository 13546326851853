$font-Comfortaa: "Comfortaa", cursive;
$font-FjallaOne: "Fjalla One", sans-serif;

$width-site-max: 1184px; //1184px
$width-site-min: 1024px;

$grid-gutter: 15px;
$grid-gutter-mobile: 20px;

/* ================ SASS HELPERS ================ */
/* ============================================================================
	Convert pixels to ems
	eg. for a relational value of 12px write em(12) when the parent is 16px
	if the parent is another value say 24px write em(12, 24)
	Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_px-to-em.scss
============================================================================== */
@function em($pxval, $base: $font-size-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

/* ================ #Mixins ================ */
@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  // sass-lint:disable no-misspelled-properties
  *zoom: 1;
}

@mixin user-select($value: none) {
  @include prefix("user-select", #{$value}, moz ms webkit spec);
}

/* ================ Media Query Mixin ================ */
@mixin media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query == $name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn 'Breakpoint "#{$media-query}" does not exist';
  }
}

@mixin overlay($z-index: null) {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-image-overlay;
    opacity: $opacity-image-overlay;

    @if ($z-index) {
      z-index: $z-index;
    }
  }
}

@mixin centered($direction: "xy") {
  @if $direction == "xy" {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else {
    @if $direction == "x" {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    @if $direction == "y" {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

%reset {
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  list-style: none;
  appearance: none;
}

$grid-medium: 750px;
$grid-large: 1025px; //990px
$grid-widescreen: 1200px;

$small: "small";
$medium: "medium";
$medium-down: "medium-down";
$medium-up: "medium-up";
$large: "large";
$large-down: "large-down";
$large-up: "large-up";
$widescreen: "widescreen";

$grid-breakpoints: (
  $small "(max-width: #{$grid-medium - 1})",
  $medium "(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})",
  $medium-down "(max-width: #{$grid-large - 1})",
  $medium-up "(min-width: #{$grid-medium})",
  $large "(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})",
  $large-down "(max-width: #{$grid-widescreen - 1})",
  $large-up "(min-width: #{$grid-large})",
  $widescreen "(min-width: #{$grid-widescreen})"
);
