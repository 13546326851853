$modalZindex: 900;

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  outline: 0;
  justify-content: center;
  align-items: center;
  transition: background 0.6s ease 0s;
  background: rgba(77, 77, 77, 0);
  z-index: -1;
  visibility: hidden;
  display: flex;

  &.is-active {
    transition: all 0.6s ease 0.1s;
    background: rgba(77, 77, 77, 0.4);
    visibility: visible;
    z-index: $modalZindex;

    + .window {
      filter: blur(8px);
    }
  }
}

.modal-button-back {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  color: #22a7f1;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  &:active {
    filter: drop-shadow(0 0 5px #e1e1e1);
  }

  .icon {
    display: inline-block;
    width: 17px;
    fill: #e1e1e1;
    transition: fill 0.3s ease 0s;
    height: 12px;
    margin-right: 8px;
  }

  &__label {
    display: inline-block;
    color: #e1e1e1;
    font-size: 12px;
    vertical-align: top;
    text-decoration: underline;
  }

  &:hover {
    fill: #e1e1e1;

    .modal-button-back__label {
      text-decoration: none;
    }
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  opacity: 1;
  max-width: 780px;
  z-index: ($modalZindex + 10);

  &--competetive {
    background-color: #414141;
  }
}

.modal-header {
  position: relative;
  padding: 15px 20px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid #4c4c4c;

  &--indent {
    padding: 30px 20px;
  }

  &-control {
    display: block;
    position: absolute;
    right: 35px;
    top: 30px;

    &__btn {
      padding: 0;
      margin-left: 15px;

      .icon {
        width: 48px;
        height: 48px;
        fill: #ffffff;
        transition: fill 0.3s ease 0s, transform 0.1s ease 0s;
      }

      &:active {
        .icon {
          transform: rotate(180deg) scale(0.8);
        }
      }
    }
  }
}

.modal-title {
  font-family: $font-Comfortaa;
  color: #ffffff;
  padding: 5px 20px 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;

  &--win {
    color: #ffffff;
  }
}

.modal-caption {
  margin-top: 5px;
  border-top: rgba(255, 255, 255, 0.34);
  text-align: center;

  &__count {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    color: rgba(#ffffff, 0.7);
    font-size: 12px;
    line-height: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.34);
    padding-top: 8px;
  }
}

.modal-body {
  flex: 1 1 auto;
  padding: 20px 35px;

  &--relative {
    position: relative;
  }
}

.modal-body-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: rgba(196, 196, 196, 0.1);
  box-sizing: border-box;
  margin: 25px -35px -20px;

  &__btn-create {
    color: #ffffff;
    font-size: 12px;

    &.is-disabled {
      color: #908d8d;
      pointer-events: none;
    }
  }
}

.modal-body-scrollbar {
  position: relative;
  height: 300px;

  &--sm {
    height: 265px;
  }
}

.modal-body-chart {
  position: relative;
  padding: 20px;

  .clipboard-buttons {
    position: absolute;
    right: 50px;
    top: 17px;
    z-index: 100;

    .clipboard-buttons__item {
      margin-left: 0;
      margin-right: 0;

      &:before {
        display: none;
      }
    }

    .button-clipboard {
      display: none;
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: #000000;
    }

    // &:active{
    // 	.icon-scissors{
    // 		filter: drop-shadow(0 0 5px #06c8ff);
    // 		fill: #22A8F3;
    // 	}
    // }
  }
}

.modal-win {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#f5f5f5, 1);
  z-index: ($modalZindex + 20);

  &--primary {
    background-color: rgba(#414141, 1);
  }

  &-footer {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 35px;
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-top: 1px solid #dbdbdb;

  &__btn-create {
    font-size: 14px;
    color: #ffffff;

    &.is-disabled {
      color: #908d8d;
      pointer-events: none;
    }
  }
}

.modal-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  padding-left: 15px;
  padding-right: 15px;

  &--center {
    justify-content: center;
  }

  &__field {
    flex-basis: 330px;

    .input-text-search {
      color: #ffffff;
    }
  }

  &-buttons {
    &--separe {
      border-top: 1px solid #ffffff;
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .button {
      margin-left: 15px;
      margin-right: 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-button-close {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 15px;
  width: 15px;
  outline: none;
  color: #000000;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;

  .icon-close {
    display: block;
    fill: #000000;
    transition: fill 0.3s ease 0s, transform 0.1s ease 0s;

    &--secondary {
      fill: #c4c4c4;
    }
  }

  &:hover {
    .icon-close {
      fill: #22a7f1;

      &--secondary {
        fill: #c4c4c4;
      }
    }
  }

  &:active {
    filter: drop-shadow(0 0 5px #22a7f1);

    .icon-close {
      fill: #22a7f1;
      transform: rotate(180deg) scale(0.8);

      &--secondary {
        fill: #c4c4c4;
      }
    }
  }
}
