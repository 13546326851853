@import "../../styles/_mixines";

.cell {
  vertical-align: middle;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  &--sorting {
    cursor: pointer;
    position: relative;

    // -webkit-user-select: none;
    // user-select: none;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: 5px;
      transform: translateX(-50%);
    }

    &-asc {
      &:before {
        border-style: solid;
        border-width: 0 6px 5px;
        border-color: transparent transparent #3c4450;
        border-color: transparent transparent currentcolor;
      }
    }

    &-desc {
      &:before {
        border-style: solid;
        border-width: 5px 6px 0;
        border-color: #3c4450 transparent transparent;
        border-color: currentcolor transparent transparent;
      }
    }
  }

  &-text {
    &--right {
      text-align: right;

      &.cell-text {
        padding-right: 25px;
      }
    }

    &--left {
      text-align: left;

      &.cell-text {
        padding-left: 25px;
      }
    }
  }

  &--group {
    background-color: #dae4f2;
    font-size: 16px;
    font-weight: 700;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &-holder {
    position: relative;
  }

  &-head {
    background-color: #ebf0f2;

    .cell {
      color: #000000;
      text-transform: uppercase;
      padding-top: 20px;
      padding-bottom: 14px;
      font: 700 13px/16px $font-Comfortaa;

      &--base {
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        color: #808080;
        font-weight: 700px;
        font-size: 10px;
        line-height: 12px;
      }

      &--nps,
      &--name-exellent,
      &--theme-b {
        background-color: #e4e9eb;
      }
    }
  }

  &-foot {
    background-color: #dbe0e2;

    .cell {
      color: #939393;
      text-transform: uppercase;
      padding-top: 10px;
      padding-bottom: 10px;
      font: 400 14px/20px $font-Comfortaa;

      &--theme-c {
        background-color: rgba(#06c8ff, 0.2);
      }
    }
  }

  &-body {
    background-color: #ffffff;

    .cell {
      color: #000000;
      vertical-align: middle;
      border-bottom: 1px dashed #e8e8e8;
      padding-top: 9px;
      padding-bottom: 5px;
      transition: background-color 0.1s ease 0s;

      &--nps,
      &--name-exellent,
      &--theme-b {
        background-color: #f9f9f9;
      }
    }
  }
}

.table-fixed {
  position: fixed;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0s;

  &.is-sticky {
    opacity: 1;
    visibility: visible;
  }
}

.table-scrollbar {
  position: relative;
  max-height: 350px;
}

.table-cloud {
  .table-head {
    .cell--weight {
      width: 80px;
    }
  }

  .table-body {
    .cell {
      padding-top: 5px;
      padding-bottom: 5px;
      font: 400 14px/16px $font-Comfortaa;
      color: #000000;

      &--word {
        text-align: left;
        padding-left: 15px;
      }

      &--weight {
        width: 80px;
        color: #adadad;
      }
    }
  }
}

.table-ranking {
  table-layout: fixed;

  .cell--name-chain {
    width: 130px;
    text-align: left;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 12px;
  }

  .cell--name-base {
    background-color: rgba(34, 167, 241, 0.31);
    width: 100px;
  }

  &--small {
    .cell--name-chain {
      width: 400px;
    }
  }

  .content-text-chain-link {
    color: #000000;
    text-decoration: none;
  }
}

.table-history-ranking {
  table-layout: fixed;

  .cell--name-chain {
    width: 250px;
    text-align: left;
    padding-left: 10px;
    font-size: 12px;
  }

  .cell--name-base {
    background-color: rgba(34, 167, 241, 0.3);
    width: 200px;
  }

  .cell--name-range-from,
  .cell--name-range-to {
    background-color: rgba(34, 167, 241, 0.1);
  }

  .content-text-chain-link {
    color: #000000;
    text-decoration: none;
  }

  .table-head {
    .cell--name-base {
      color: #000000;
      font: 700 13px/16px $font-Comfortaa;
    }

    .cell--name-range-from,
    .cell--name-range-to,
    .cell--name-difference {
      color: #808080;
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.cell-text-string {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}

.table-chain {
  .cell--chain,
  .cell--compare-to {
    width: 250px;
  }

  .cell--difference {
    width: 180px;
  }

  .cell--metric {
    .cell-text-string {
      white-space: normal;
    }
  }

  .table-body {
    .cell--group {
      text-align: left;
      padding-left: 20px;
    }

    .cell--metric {
      text-align: right;
      padding-right: 20px;
    }

    .cell--chain {
      background-color: rgba(#06c8ff, 0.15);
    }

    .cell--nps {
      background-color: #dae4f2;
    }
  }

  .table-head {
    .cell {
      color: #000000;
      font-size: 14px;
      line-height: 18px;
      font-family: $font-Comfortaa;
    }

    .cell--chain {
      color: #ffffff;
      background-color: rgba(#06c8ff, 0.6);
    }

    .cell--chain,
    .cell--compare-to {
      position: relative;

      .cell-text-string {
        width: 220px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .table-foot {
    .cell--metric {
      text-align: right;
      padding-right: 20px;
    }

    .cell--chain {
      background-color: rgba(#06c8ff, 0.2);
    }
  }
}

.table-body tr {
  &.highlight {
    .cell {
      // box-shadow: 0 0 50px 50px rgba(249, 184, 76,0.1) inset;
      box-shadow: 0 0 50px 50px rgba(34, 167, 241, 0.2) inset;
    }
  }

  &:hover {
    .cell {
      box-shadow: 0 0 50px 50px rgba(34, 167, 241, 0.1) inset;
    }
  }

  &:last-child {
    .cell {
      border-bottom: none;
    }
  }
}

.cell-text-chain-link {
  cursor: pointer;
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #22a7f1;
  }
}

.content-text-difference {
  display: inline-block;
  width: 62px;
  text-align: left;
  position: relative;

  &__value {
    display: inline-block;
    width: 30px;
    line-height: 26px;
    text-align: right;
  }

  .icon-direction {
    position: absolute;
    height: 23px;
    width: 23px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;

    &--up {
      background-color: #43b975;
    }

    &--down {
      background-color: #f33b43;
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}
