@function makecharthadow($count) {
  $val: 0 20px 0 0 #ebf0f2 inset, 0 21px 0 0 #dde1e3 inset;

  @for $i from 2 through $count {
    $val: #{$val}, 0 #{$i * 20}px 0 0 #ebf0f2 inset,
      0 #{($i * 20) + 1}px 0 0 #dde1e3 inset;
  }

  @return $val;
}

.scatter-chart {
  position: relative;
  background-color: #ffffff;

  &-scatter {
    max-width: 964px;
    box-sizing: border-box;
    background-color: #ebf0f2;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 270px;
    padding-left: 5px;
    padding-right: 5px;

    /*
		&:before{
			content: "";
			position: absolute;
			left: 50%;
			top: 2px;
			transform: translateX(-50%);
			width: 96%;
			height: 90%;
			box-shadow: makecharthadow(20);
		}
		*/

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 1px;
      border-bottom: 1px dashed #aba2a2;
      z-index: 0;
      width: calc(100% - 20px);
      left: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      border-right: 1px dashed #aba2a2;
      width: 1px;
      transform: translate(-50%, -50%);
      z-index: 0;
      height: calc(100% - 20px);
      left: 50%;
      top: 50%;
    }

    .highcharts-container {
      z-index: 2 !important;
    }

    .highcharts-yaxis-grid,
    .highcharts-xaxis-grid {
      .highcharts-grid-line {
        &:last-child,
        &:first-child {
          display: none;
        }
      }
    }
  }

  &-label {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 16px;
    font-weight: 700;
    color: #6a6a6a;

    &--left,
    &--right {
      position: absolute;
      width: 110px;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
    }

    &--left {
      left: 0;
      padding-right: 5px;
      text-align: right;
    }

    &--right {
      right: 0;
      padding-left: 5px;
    }

    &--top {
      padding-top: 14px;
      padding-bottom: 10px;
      text-align: center;
    }

    &--bottom {
      padding-top: 14px;
      padding-bottom: 10px;
      text-align: center;
    }
  }

  &-footer {
    padding: 15px 20px;
    color: #404040;
    background-color: #ededed;
    text-align: left;
    margin-bottom: -10px;
    border-radius: 0 0 9px 9px;
  }
}
