@import "../../../styles/_mixines";

.menu-items-container {
  padding: 30px;
}

.menu-items-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: -16px;
}

.menu-items-item {
  flex-basis: 25%;
  padding: 16px;
  box-sizing: border-box;

  &__image {
    position: relative;
    margin: 0;
    padding: 0;
    height: 180px;
    overflow: hidden;
    border: 1px solid #ededed;
    box-sizing: border-box;

    // &:before {
    // 	content: "";
    // 	display: block;
    // 	position: absolute;
    // 	width: 100%;
    // 	height: 100%;
    // 	z-index: 1;
    // 	box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2) inset;
    // 	transition: 0.3s cubic-bezier(.97,-0.86,0,2.02) 0s;
    // }

    img {
      display: block;
      // max-width: 100%;
      width: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      // transition: 0.3s ease 0s;
    }
  }

  &:hover {
    .menu-items-item__image {
      &:before {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2) inset;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background-color: #3c4450;
    color: #ffffff;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    line-height: 19px;
  }

  &__name {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    padding-left: 8px;
  }

  &__value {
    display: block;
    text-align: right;
    background-color: #212730;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 8px;
    margin-left: 10px;
  }

  &__caption {
    display: block;
    font-size: 11px;
    white-space: nowrap;
  }
}
