@import "../../../styles/_mixines";

.cloud {
  display: flex;
  justify-content: space-between;

  &-screen {
    height: 400px;
    position: relative;
    width: 800px;
    background-color: #ffffff;

    &-canvas {
      display: none;
    }

    &-svg {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 50%;
      transform: scale(0.75) translate(-50%, -50%);
      transform-origin: 0 0 0;

      text {
        transition: all 0.2s ease 0s;
      }
    }

    .icon-cloud {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  &-table {
    margin-right: 20px;
    flex-basis: 300px;

    &__message {
      text-align: center;
      font-size: 12px;
      color: #808080;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .table-holder {
      padding-bottom: 0;
    }

    .table-scrollbar {
      max-height: 320px;
    }
  }
}

.cloud-search {
  .icon-search {
    fill: #a4a4a4;
    position: absolute;
    right: 0;
    left: auto;
  }

  .input-text-search-line {
    border-bottom: 1px solid #656565;
  }
}

.table-cloud {
  table-layout: fixed;

  &-head {
    .cell--word {
      text-align: center;
    }
  }

  &-body {
    .cell--word {
      text-align: left;
      padding-left: 20px;
    }
  }
}
