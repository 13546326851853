@keyframes fade-in-box {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.home {
  height: 100%;
  width: 100%;
  position: absolute;
  min-width: 1024px;
  min-height: 640px;
  overflow: hidden;

  &-video {
    bottom: 0;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    right: 0;
    width: auto;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.6s ease 0s;

    &--loaded {
      opacity: 1;
    }

    &-holder {
      filter: blur(2px);
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.home-logo {
  position: absolute;
  right: 60px;
  top: 80px;
  color: #ffffff;
  z-index: 10;
  height: 51px;
}

.home-logo-datassential {
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 125px;
  z-index: 10;

  &__svg {
    display: block;
    max-width: 100%;
    backface-visibility: hidden;
  }
}

.cluster-list {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.cluster {
  position: relative;
  height: 260px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
  transform: scale(0.5);
  opacity: 0;

  &:hover {
    .cluster-list__liquid {
      opacity: 0.8;
      transition: opacity 0.2s ease 0s;
    }
  }

  &--active {
    animation-name: fade-in-box;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }

  &-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 228px;
    width: 228px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    transition: opacity 0.2s ease 0s;
    z-index: 15;
    border-radius: 50%;
    background: #333333;

    &:hover {
      opacity: 0.8;
    }

    &__caption {
      text-transform: uppercase;
      font-family: $font-Comfortaa;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #ffffff;
    }
  }

  &__liquid {
    width: 1500px;
    height: 1500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.47);
    transition: opacity 0.2s ease 0s;
  }

  &--disabled {
    opacity: 0.8;

    .cluster-box {
      cursor: default;
    }
  }
}

.liquid {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__holder {
    width: 1500px;
    height: 1500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.47);
    transition: opacity 0.2s ease 0s;
  }
}

.cluster-tooltip {
  position: absolute;
  bottom: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0s;

  .cluster:hover & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease 0s;
  }

  &__content {
    display: block;
    color: #ffffff;
    background-color: #333333;
    font-size: 12px;
    line-height: 17px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    width: 275px;
    margin-bottom: 10px;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 5px 0;
      border-color: #333333 transparent transparent;
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
