.card-competitive-header {
  display: flex;
  justify-content: space-between;
}

.button-legend {
  position: relative;
  flex-basis: 360px;
  max-width: 360px;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  padding: 8px 10px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  white-space: normal;
  cursor: pointer;
  justify-content: center;
  transition: opacity 0.2s ease 0s;

  &__line {
    position: absolute;
    height: 20px;
    width: 1px;
    top: 100%;
    z-index: 10;
  }

  &--crossovervalue {
    .button-legend__line {
      left: 26px;
    }
  }

  &--commonvalue {
    .button-legend__line {
      right: 26px;
    }
  }

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
    opacity: 0.85;
  }

  &.is-selected {
    pointer-events: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
  }

  &__caption {
    font-size: 14px;
  }

  &__description {
    box-sizing: border-box;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 14px;
    text-align: center;
  }
}

.card-competitive-chart {
  margin-top: 20px;
  margin-left: -6px;
  margin-right: -6px;

  .highcharts-container {
    margin-left: auto;
    margin-right: auto;
  }
}
