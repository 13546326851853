.dropdown-toggle-button {
  position: absolute;
  right: 0;
  width: 26px;
  background-color: #3c4450;
  height: 50px;
  transform: translateX(100%);
  border-radius: 0 9px 9px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease 0s;
  overflow: hidden;
  color: #ffffff;

  &__caption {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease 0s;
  }

  &__icon {
    width: 12px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease 0s;
  }

  &:hover {
    width: 60px;

    .dropdown-toggle-button__caption {
      opacity: 1;
      visibility: visible;
    }

    .dropdown-toggle-button__icon {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.dropdown-toggle-menu {
  position: absolute;
  width: 270px;
  border-left: 1px solid #e9e6e6;
  background-color: #ffffff;
  right: 0;
  top: 0;
  z-index: 30;
  overflow: hidden;
  height: 100%;
}

.dropdown-toggle-header {
  height: 45px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #3a414c;
  }

  &__display {
    display: block;
    position: relative;
    border-bottom: 1px solid #e9e6e6;
    box-sizing: border-box;
    color: #888888;
    font-size: 14px;
    padding: 12px 80px 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 26px;
  }
}

.dropdown-toggle-list {
  position: relative;
  max-height: calc(100% - 55px);
  margin-right: 2px;
  margin-top: 5px;
}

.dropdown-toggle-group {
  &__caption {
    background-color: #ffffff;
    line-height: 13px;
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    padding: 6px 10px;
    text-transform: uppercase;
  }

  &__item {
    padding: 3px 10px 3px 40px;
    cursor: pointer;
    font-size: 12px;
    color: #898989;
    line-height: 18px;
    box-sizing: border-box;
    position: relative;

    .icon {
      height: 16px;
      width: 16px;
      display: inline-block;
      background-color: #d9d9d9;
      border-radius: 50%;
      margin-right: 10px;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);

      &-apply {
        fill: #ffffff;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.6);
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease 0s;
      }
    }

    &:hover {
      background-color: #eaeaea;
    }

    &.is-selected {
      .icon {
        background-color: #9fcd63;

        &-apply {
          transform: translate(-50%, -50%) scale(1);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
