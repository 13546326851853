.food-tab-tooltip {
  min-width: 390px;
  box-sizing: border-box;
  // padding-top: 10px;
  margin-top: -5px;

  &__enter {
    position: relative;
    background-color: #ffffff;
    padding: 15px 20px 15px 15px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);

    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 4px;
      border-color: transparent transparent #ffffff;
      top: -4px;
      left: 30px;
    }
  }

  &__caption {
    font-weight: bold;
  }

  &__content {
    color: #50565b;
    font: 300 14px/22px "Comfortaa", cursive;
    margin-top: 5px;
  }

  &__button {
    border-bottom: 1px solid rgba(#22a7f1, 1);

    &:hover {
      border-bottom: 1px solid rgba(#22a7f1, 0);
    }
  }
}
