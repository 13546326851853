@import "../../../styles/_mixines";

.funnel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.funnel-info {
  width: 400px;
  position: relative;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e7e6e6;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 15px 128px 15px 30px;

  .icon-funnel {
    width: 105px;
    position: absolute;
    height: 146px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
}

.funnel-categories {
  position: relative;
  display: flex;

  &__map {
    position: absolute;
    width: 103%;
    margin: 14px 0 0;
    padding: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    pointer-events: none;
  }
}

.funnel-category {
  width: 130px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: #ebf0f2;
  text-align: center;
  box-sizing: border-box;
  padding: 14px 5px;
  position: relative;
  font-size: 13px;
  font-family: $font-Comfortaa;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  }

  &__name {
    font-weight: 700;
    line-height: 20px;
    color: #000000;
    text-transform: uppercase;
  }

  &__value {
    margin-top: 2px;
    color: #9aa8ad;
    font: normal 36px/38px $font-FjallaOne;
    vertical-align: bottom;
  }

  &__percent {
    font-size: 26px;
  }

  &__caption {
    position: absolute;
    font-weight: 300;
    bottom: 0;
    left: 50%;
    width: 80%;
    line-height: 14px;
    padding: 11px 5px;
    box-sizing: border-box;
    text-align: center;
    transform: translateX(-50%);
  }
}

.funnel-footer {
  background: rgba(235, 240, 242, 0.7);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.funnel-footer-norms {
  flex: 1;
  display: flex;
}

.funnel-footer-norms__item {
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  width: 130px;
  flex-basis: 130px;
  align-items: center;
  justify-content: center;
  color: #9aa8ad;
  font: normal 18px/20px $font-FjallaOne;
}

.funnel-footer-sidebar {
  display: flex;
  align-items: center;
  padding-right: 16px;
  box-sizing: border-box;

  .funnel-chains {
    position: relative;
    margin-right: 12px;

    &__caption {
      cursor: pointer;
      color: #9aa8ad;
      line-height: 20px;
    }

    &__compare-to {
      cursor: pointer;
      color: #000000;
      text-transform: uppercase;
      line-height: 20px;
    }
  }

  .button-call-modal {
    width: 40px;
    height: 28px;
    background: #4fc297;
    border-radius: 4px;
    color: #ffffff;

    .icon-way {
      width: 16px;
    }
  }
}

.funnel-category-tooltip {
  position: absolute;
  left: 0;
  top: -9px;
  transform: translateY(-100%) translateX(10px);
  min-width: 400px;
  text-align: left;
  padding: 5px 10px;
  color: #ffffff;
  background-color: #3c4450;
  font-size: 12px;
  line-height: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease 0s;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 65px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0;
    border-color: #3c4450 transparent transparent;
    transform: translateX(-50%);
  }

  .funnel-category:hover & {
    visibility: visible;
    transform: translateY(-100%) translateX(0);
    opacity: 1;
    z-index: 10;
    transition: all 0.2s ease 0s;
  }
}

.funnel-chains-tooltip {
  position: absolute;
  background-color: #fcfcfc;
  width: 185px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: #000000;
  overflow: hidden;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0.3s;
  bottom: 40px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  &__list {
    text-align: left;
    font-size: 12px;
    max-height: 300px;
    position: relative;
    box-sizing: border-box;
    padding-right: 7px;
    margin: 15px 5px 10px 15px;

    &__item {
      &.is-disabled {
        pointer-events: none;
        color: #898989;
      }
    }
  }

  .funnel-footer-sidebar .funnel-chains:hover & {
    opacity: 1;
    visibility: visible;
  }
}

.funnel-chains-tooltip-footer {
  background-color: #f5f5f5;
  font-size: 13px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
