.input-text {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding: 4px 15px;
  color: #000000;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  appearance: none;
  resize: none;
  font-family: $font-Comfortaa;
  font-size: 12px;
  line-height: 38px;
  transition: border-color ease 0.15s;

  &--area {
    padding: 20px;
    resize: none;
    min-height: 100px;
  }

  &:focus {
    border-color: #22a8f3;
    outline: none;
    box-shadow: 0 0 3px 0 #22a8f3 inset;
  }

  &.error {
    border-color: #e32429;
  }
}

.input-text-search {
  background-color: transparent;
  background-image: none;
  font-family: $font-Comfortaa;
  font-size: 12px;
  line-height: 28px;
  height: 28px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  resize: none;

  &--modal-chain {
    text-align: center;
    color: #ffffff;
  }

  &--text-indent {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-holder {
    position: relative;

    .icon-search {
      fill: #ffffff;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: fill 0.2s ease 0s;
    }
  }

  &-line {
    border-bottom: 1px solid #ffffff;
    transition: border-bottom 0.3s ease 0s;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 0;
      bottom: 0;
      height: 1px;
      background-color: #22a8f3;
      transition: width 0.6s ease 0s;
    }
  }

  &:focus {
    ~ .input-text-search-line {
      border-bottom: 1px solid transparent;

      &:after {
        width: 100%;
      }
    }
  }
}

.input-label {
  font-family: $font-Comfortaa;
  font-size: 16px;
  color: #ffffff;
}

.input-holder {
  position: relative;
}

.buttons-holder {
  margin-top: 10px;
}

.button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  appearance: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  font-family: inherit;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  &-block {
    display: block;
    width: 100%;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button-main {
  border-radius: 3px;
  padding: 15px 25px 12px;
  background-color: transparent;
  font-size: 14px;
  font-family: $font-Comfortaa;
  line-height: 100%;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease 0s;

  &:hover {
    box-shadow: 0 0 0 1px rgba(#ffffff, 1) inset,
      0 1px 4px rgba(#ffffff, 1) inset;
  }
}

.button-primary {
  border-radius: 3px;
  padding: 15px 25px 12px;
  background-color: #22a8f3;
  font-size: 14px;
  font-family: $font-Comfortaa;
  line-height: 100%;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease 0s;

  &:hover {
    box-shadow: 0 0 0 1px #0377b7 inset,
      0 1px 4px rgba(255, 255, 255, 0.8) inset;
  }

  &--disabled {
    background-color: #c7c7c7;

    &:hover {
      box-shadow: 0 0 0 1px #c7c7c7 inset,
        0 1px 4px rgba(255, 255, 255, 0.8) inset;
    }
  }

  &--trial {
    background-color: #c7c7c7;
    pointer-events: none;
  }
}

.button-period {
  padding: 15px 25px 12px;
  border-radius: 0 0 9px;
  height: 100%;
  background-color: #c6c7c8;
  font-size: 11px;
  font-family: $font-Comfortaa;
  line-height: 100%;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  transition: box-shadow 0.3s ease 0s;

  &:hover {
    box-shadow: 0 0 0 1px #989a9c inset,
      0 1px 4px rgba(255, 255, 255, 0.8) inset;
  }
}

.button-copy {
  background-color: transparent;
  color: #ffffff;
  border-bottom: 1px solid transparent;
  padding: 0;
  margin: 0;
  text-transform: lowercase;

  &:hover {
    border-bottom: 1px solid rgba(#ffffff, 0.5);
  }
}

.button-link {
  background-color: transparent;
  color: #22a7f1;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid rgba(#22a7f1, 1);
  }

  &--primary {
    color: #ffffff;

    &:hover {
      border-bottom: 1px solid rgba(#ffffff, 1);
    }
  }
}

.button-screenshot {
  .icon-scissors {
    display: block;
  }

  .icon-copy {
    display: none;
  }

  &--ready {
    .icon-scissors {
      display: none;
    }

    .icon-copy {
      display: block;
    }
  }
}

.button-report-label {
  display: inline-block;
  box-sizing: border-box;
  padding-top: 3px;
  text-transform: uppercase;
  font-family: "Comfortaa", cursive;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  z-index: 1;

  &--icon {
    padding-left: 25px;

    .icon {
      width: 18px;
      height: 18px;
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotateY(0deg);
      transition: 1s ease-in-out 0s;
    }
  }
}

.button-report {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  border-radius: 3px;
  background-color: #22a8f3;
  font-size: 14px;
  font-family: $font-Comfortaa;
  color: #ffffff;

  &--hidden {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  &__label {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    text-transform: uppercase;
    white-space: nowrap;
    transform: translate(-50%, 0);
    transition: all 0.3s ease 0s;

    .icon {
      width: 18px;
      height: 18px;
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotateY(0deg);
      transition: 1s ease-in-out 0s;
    }
  }

  &__buttons {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 100%);
    transition: all 0.3s ease 0s;
  }

  &__btn {
    display: inline-block;
    padding: 0;
    color: #ffffff;
    border: 1px solid #ffffff;
    height: 60%;
    text-align: center;
    border-radius: 3px;
    width: 72px;
    margin-left: 3px;
    margin-right: 3px;
    transition: all 0.2s ease 0s;
    background-color: transparent;

    &:hover {
      color: #6a6a6a;
      background-color: #ffffff;
    }
  }

  &__busy {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
    color: #ffffff;
    border-radius: 3px;
    background-color: #1194de;
    box-shadow: 0 0 3px 0 #0377b7 inset;
    overflow: hidden;
    user-select: none;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      background-size: 35px 35px;
      animation: animation-progress 2s linear infinite;
    }
  }

  &-holder {
    position: relative;
    width: 100%;
    height: 42px;

    &--small {
      height: 30px;

      .button-report__btn {
        font-size: 10px;
      }
    }
  }

  &--busy {
    pointer-events: none;

    .button-report__busy {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease 0s;
    }

    .button-report__ready {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease 0s;
    }
  }

  &:hover {
    box-shadow: 0 0 0 1px #0377b7 inset,
      0 1px 4px rgba(255, 255, 255, 0.8) inset;

    .button-report__label {
      transform: translate(-50%, -100%);
    }

    .button-report__buttons {
      transform: translate(0, 0);
    }
  }
}

.call-modal {
  box-sizing: border-box;

  &__button {
    .icon {
      width: 19px;
      height: 16px;
      fill: #ffffff;
      vertical-align: top;
      margin-right: 5px;
    }

    &:active {
      .icon {
        filter: drop-shadow(0 0 5px #ffffff);
        transform: scale(0.9);
      }
    }
  }
}

.button-report__label:hover .icon {
  transform: translateY(-50%) rotateY(360deg);
  transition: 1s ease 0s;
}

.button-scroll-up {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #22a8f3;
  padding: 15px;
  border-radius: 3px;
  color: #ffffff;
}

@keyframes animation-progress {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 35px 35px;
  }
}
