.animation-show-notification {
  transform: translateY(30px);
  opacity: 0;
  animation-name: animation-show-notification;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  @keyframes animation-show-notification {
    from {
      transform: translateY(30px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.animation-show-modal-win {
  transform: scale(0.5);
  opacity: 0;
  animation-name: animation-show-modal-win;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  @keyframes animation-show-modal-win {
    from {
      transform: scale(0.5);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.animation-close-modal-win {
  transform: scale(1);
  opacity: 1;
  animation-name: animation-close-modal-win;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  @keyframes animation-close-modal-win {
    from {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.5);
      opacity: 0;
    }
  }
}

// .animation-show-modal-content{

// 	animation-name: animation-show-modal-content;
// 	animation-duration: 1s;
// 	animation-delay: 0.1s;
// 	animation-fill-mode: forwards;

// 	@keyframes animation-show-modal-content {
// 		from {
// 			opacity: 0;
// 		}
// 		to {
// 			opacity: 1;
// 		}
// 	}
// }

.animation-show-navigation-panel {
  &.is-sticky {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    animation-name: animation-show-navigation-panel;
    animation-duration: 0.4s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
  }

  @keyframes animation-show-navigation-panel {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.animation-show-tooltip {
  opacity: 0;
  visibility: hidden;
  animation-name: animation-show-tooltip;
  animation-duration: 0.4s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  @keyframes animation-show-tooltip {
    from {
      opacity: 0;
      visibility: hidden;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }
}

.animation-logo-placeholder {
  animation-iteration-count: infinite;
  animation-name: animation-logo-placeholder;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-delay: 0s;

  @keyframes animation-logo-placeholder {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
