.chain-list {
  column-count: 3;
  column-gap: 50px;

  &__leter {
    color: #ffffff;
    width: 65px;
    background-color: #22a8f3;
    border-radius: 9px;
    font-size: 32px;
    font-weight: 700;
    line-height: 66px;
    padding-top: 5px;
    margin-bottom: 12px;
    display: none;
    align-items: center;
    justify-content: center;
  }

  &__name {
    position: relative;
    display: block;
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 5px;

    &.is-enabled {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 2px;
        height: 50%;
        background-color: rgba(#22a8f3, 0.9);
        opacity: 0;
        transform: translateX(-30px) translateY(-50%);
        visibility: hidden;
        transition: 0.3s ease 0s;
      }

      &:hover {
        color: #22a8f3;

        &:before {
          opacity: 1;
          visibility: visible;
          transform: translateX(-10px) translateY(-50%);
          transition: 0.3s ease 0s;
        }
      }
    }

    &.is-disabled {
      color: #959595;
      pointer-events: none;
    }
  }

  &--restaurants {
    column-count: 4;

    .chain-list-block {
      width: 100%;
      display: inline-block;
      margin-bottom: 35px;
    }

    .chain-list__leter {
      display: flex;
    }

    .chain-list__name {
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 0;
    }
  }
}
