.clipboard-buttons {
  display: flex;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  align-items: center;

  &__item {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    &:before {
      content: "";
      height: 16px;
      width: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #ececec;
    }

    &:first-child {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__button {
    padding: 0;

    .icon {
      width: 22px;
      height: 22px;
      fill: #ffffff;
      transition: transform 0.3s ease 0s;
    }

    &:active {
      .icon {
        transform: rotate(30deg) scale(0.9);
        filter: drop-shadow(0 0 5px #ffffff);
      }
    }
  }
}
