.notification {
  position: fixed;
  width: 330px;
  height: calc(100% - 200px);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  right: 25px;
  justify-content: flex-end;
  z-index: 990;
  pointer-events: none;

  &--center {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    right: auto;
    height: 50%;
  }

  &-item {
    position: relative;
    background-color: #3c4450;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
    color: #ffffff;
    padding: 12px 20px;
    box-shadow: 0 0 0 0 #f53b44;
    text-align: center;
    font-size: 13px;
    border-radius: 3px;
    transform: translateY(30px);
    opacity: 0;
    animation-name: notification-show;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      height: calc(100% - 8px);
      width: 3px;
      background-color: #f53b44;
      top: 50%;
      transform: translateY(-50%);
    }

    &--warning {
      background-color: #f53b44;

      &:before {
        background-color: #7e0006;
      }
    }

    &--remove {
      animation-name: notification-hide;
      animation-duration: 0.5s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes notification-show {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes notification-hide {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
