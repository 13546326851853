@use "@datassential/platform-ui-lib/lib/style/constants" as *;
@use "@datassential/platform-ui-lib/lib/style/utils" as *;

.icon {
  display: inline-block;
  width: 100%;
  height: 100%;
  stroke-width: 0;
  stroke: currentcolor;
  fill: currentcolor;
}

.section {
  margin-bottom: 25px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 9px;

  &-navigation {
    height: 46px;
    background-color: #5e6672;
    border-radius: 0 0 9px 9px;
  }
}

.section-content {
  position: relative;

  &--container {
    padding: 30px 30px 20px;
    background-color: #ffffff;
  }

  &--enter {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__message {
    padding: 7px 20px 5px;
    color: #404040;
    background: #ffffff;
    font-size: 12px;
  }
}

.section-header {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  border-radius: 9px 9px 0 0;
  background-color: #3c4450;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.section-header-meta {
  position: relative;
  padding: 14px 0 11px 23px;

  &__caption {
    font-weight: 300;
    font-size: 25px;
    line-height: 26px;
    text-transform: uppercase;
    color: #ffffff;

    .icon-ffly {
      margin-left: 20px;
      width: 74px;
      height: 20px;
      fill: #ffffff;
    }

    .field-name {
      font-size: 16px;
    }
  }

  &__description {
    color: #dbdbdb;
    font-weight: 300;
    font-size: 14px;
    padding-top: 2px;
  }

  &__dropdown {
    margin-left: 5px;
  }
}

.section-header-copyright {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
}

.section-header-controls {
  margin-left: 10px;
  display: flex;
  color: #fff;
  align-items: flex-end;
  align-self: flex-start;
  margin-top: 15px;
  flex-direction: column;
  padding-right: 15px;

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .base-size {
    text-transform: lowercase;
    display: block;
    margin-left: 14px;
    font-size: 13px;
    line-height: 14px;
    color: #b4bdcc;
    margin-top: 14px;
  }
}

.section-footer {
  border-radius: 0 0 9px 9px;
  background-color: #ffffff;
  min-height: 10px;
  overflow: hidden;
}

.section-footer-enter {
  border-radius: 0 0 9px 9px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  min-height: 41px;
}

.section-footer-question {
  font-weight: 300;
  font-size: 14px;
  color: #404040;
  max-width: 990px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 9px;
  padding-bottom: 9px;

  &__char {
    font-weight: 700;
  }
}

.section-footer-button-chart {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #c6c7c8;
}

.section-footer-swich-button {
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-back {
    width: 11px;
    height: 20px;
    margin-right: 15px;
  }

  .icon-forward {
    width: 11px;
    height: 20px;
    margin-left: 15px;
  }
}

.section-footer-swich {
  box-sizing: border-box;
  border-radius: 0 0 9px 9px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #22a8f3;
  min-height: 41px;

  .section-footer-swich-button {
    flex: 1 0 auto;
  }

  &--list {
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;

    .section-footer-swich-button {
      flex: 0 0 auto;
    }
  }

  &:hover {
    box-shadow: 0 0 0 1px #0377b7 inset,
      0 1px 4px rgba(255, 255, 255, 0.8) inset;
  }
}

.section-nav {
  background-color: #5e6672;

  &-container {
    height: 46px;
  }
}

.section-control {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  &-filter {
    display: flex;
    align-items: center;
  }
}

.main-score {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    flex: 0 0 180px;
    position: relative;
  }

  &__gauges {
    display: flex;
    justify-content: flex-start;
    flex: 1 0 auto;
  }

  &__gauges-item {
    margin-left: 65px;
  }

  &__panel {
    flex: 0 0 270px;
  }

  &__report {
    margin-top: 10px;
  }

  &__caption {
    position: absolute;
    left: 50%;
    bottom: 20px;
    font-size: 16px;
    color: #0d0d0d;
    line-height: 12px;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    font-weight: bold;
  }

  &__desc {
    position: absolute;
    left: -20px;
    bottom: -20px;
    font-size: 10px;
    color: #0d0d0d;
    line-height: 12px;
    width: 240px;
    text-align: center;
  }
}

.chain-logo {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 180px;
  width: 180px;
  backface-visibility: hidden;
  z-index: 0;

  &-placeholder {
    width: 50px;
    height: 50px;
    display: block;
    background: transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border-left: 3px solid #9aa6b8;
    visibility: visible;
  }

  &-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.5s ease 0s;

    &.fade-in {
      opacity: 1;

      + .chain-logo-placeholder {
        display: none;
      }
    }
  }
}

.panel-score {
  position: relative;
  background: linear-gradient(0deg, #0066ff, #0066ff),
    linear-gradient(117.97deg, #ff983d 9.22%, #ffb800 104.31%);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 9px;

  &-header {
    background: linear-gradient(0deg, #004cbf, #004cbf),
      linear-gradient(99.17deg, #ff983d 11.23%, #ffb800 103.94%);
    border-radius: 9px 9px 0 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding: 14px 0 10px;
  }

  &-content {
    color: #fff;
    padding: 8px;
    box-sizing: border-box;

    &__item {
      padding: 1px 0;
      box-sizing: border-box;
      padding-left: 70px;
    }
  }

  &-rounded {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #e32429;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);

    &__value {
      display: block;
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -50%);
      font: normal 38px/38px $font-FjallaOne;
      color: #ffffff;
    }
  }
}

.navigation-panel {
  background-color: transparent;

  &-container {
    height: 46px;
    display: flex;
    justify-content: space-between;
  }

  &-home {
    display: none;
    align-items: center;

    .icon-home {
      width: 26px;
      height: 24px;
    }

    &__caption {
      font-family: $font-Comfortaa;
      font-size: 13px;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 10px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-dropdown {
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;

    &--chain {
      padding-left: 35px;
      padding-right: 35px;
      background-color: #3c4450;
      border-radius: 0 0 6px;
    }

    .dropdown--segment {
      margin-left: 30px;
    }
  }

  &__report {
    display: none;
    align-items: center;
    flex-basis: 165px;
    align-self: center;
  }

  &__period {
    color: #b4bdcc;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 13px;
    padding-top: 2px;
    white-space: normal;
  }

  &.is-sticky {
    position: fixed;
    left: 0;
    width: 100%;
    top: calc(#{$platform-navigation-height} + #{$platform-header-height});
    z-index: 20;
    background-color: #3c4450;


    .navigation-panel-container {
      max-width: $width-site-max;
      min-width: $width-site-min;
      margin-left: auto;
      margin-right: auto;
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
      align-items: normal;

      &--wide {
        max-width: ($width-site-max + 120px);
      }
    }

    .navigation-panel-home {
      display: flex;
    }

    .navigation-panel-dropdown {
      padding-left: 20px;
      padding-right: 20px;
    }

    .navigation-panel__report {
      display: flex;
    }

    .navigation-panel__period {
      padding-right: 0;
    }
  }
}

.tab-navigation-menu {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  transition: all 0s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  &-list {
    display: block;
    position: relative;
    // width: 260px;
    transform: translateY(10px);
    background-color: #5f6773;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    padding-top: 10px;
    padding-bottom: 10px;

    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 4px;
      border-color: transparent transparent #5f6773;
      top: -4px;
      left: 30px;
    }
  }

  &__link {
    display: block;
    box-sizing: border-box;
    color: #ffffff;
    font-weight: 300;
    text-decoration: none;
    white-space: nowrap;
    line-height: 18px;
    font-size: 13px;
    padding: 6px 18px 3px;
    opacity: 0;
    transition: opacity 0s ease 0s;

    &:hover {
      background-color: #515361;
    }
  }
}

.tab-navigation {
  display: flex;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  flex: 1 0 auto;

  &__item {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 1px;
      background-color: #ececec;
      height: 14px;
      top: 50%;
      left: 0;
      position: absolute;
      transform: translateY(-50%);
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:hover {
      .tab-navigation-menu {
        opacity: 1;
        visibility: visible;
        transition: all 0.4s ease 0.1s;
      }

      .tab-navigation-menu__link {
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.6s;

        @for $i from 1 through 20 {
          $delay: $i * 0.08;
          &:nth-child(#{$i}) {
            transition-delay: #{$delay}s, 0.3s;
          }
        }
      }
    }
  }

  &__caption {
    color: #9fa4ab;
    cursor: default;
  }

  &__caption,
  &__link {
    position: relative;
    display: block;
    font-family: $font-Comfortaa;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 2px;
    padding-bottom: 1px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #59c4ff;
      transform: translateX(-50%);
      opacity: 0;
      transition: width 0.35s ease 0s, opacity 0.2s ease 0s;
    }

    &.is-active,
    &:hover {
      &:before {
        width: 100%;
        opacity: 1;
        transition: width 0.35s ease 0s, opacity 0.2s ease 0s;
      }
    }
  }
}

.tab-navigation__caption:hover {
  &:before {
    display: none;
  }
}

.comparison-section {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  font-family: $font-Comfortaa;
  margin: 55px auto;

  &__item {
    color: #ffffff;
    flex: 0 1 33.33%;
    box-sizing: border-box;
    padding: 0 15px;
    text-align: center;
  }

  &__button {
    display: block;
    width: 100%;
    background-color: transparent;
    text-align: center;

    &:hover {
      // .comparison-section__caption{
      // 	color: rgba(#22a7f1, 0.8);
      // }

      // .icon{
      // 	fill: rgba(#22a7f1, 0.8);
      // }
    }

    .icon {
      display: block;
      height: 130px;
      width: 140px;
      margin-left: auto;
      margin-right: auto;
      transition: fill 0.2s ease 0.1s;
    }

    &:active {
      padding: 0;
    }

    &[disabled] {
      background-color: transparent;
    }
  }

  &__caption {
    background-color: transparent;
    color: #ffffff;
    text-decoration: none;
    font-size: 28px;
    display: block;
    width: 100%;
    margin-top: 30px;
    white-space: normal;
  }
}

.swich-group-item {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  margin-left: 25px;
  margin-right: 25px;
  padding-bottom: 3px;
  cursor: pointer;
  user-select: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 2px;
    bottom: 0;
    background-color: #22a7f1;
    transition: 0.3s ease 0s;
    visibility: hidden;
  }

  &.is-disabled {
    // pointer-events: none;
    // opacity:0.5;
    opacity: 0.9;
  }

  &.is-active {
    pointer-events: none;

    &:before {
      visibility: visible;
      width: 100%;
    }
  }
}

.chains-list-container {
  padding-bottom: 10px;
}

.chain-filter {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  position: relative;

  &--center {
    justify-content: center;
  }

  &__search {
    flex-basis: 240px;

    .input-text-search {
      color: #ffffff;
    }
  }

  &__left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-decoration: underline;
    color: #ffffff;
    z-index: 100;

    .tooltip-preset {
      left: 0;
      top: 100%;
    }

    &:hover .tooltip-preset {
      opacity: 1;
      visibility: visible;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-size: 13px;

    &__info {
      padding-right: 30px;
    }

    &__separator {
      padding-left: 5px;
      padding-right: 5px;
    }

    &__button {
      color: #ffffff;

      &:hover {
        border-bottom: 1px solid #ffffff;
      }
    }
  }
}

.swich-group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;

  + .chain-filter {
    margin-top: 10px;
  }
}

.preset-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none outside none;
  margin: 0;
  padding: 0;

  &-holder {
    height: 352px;
    position: relative;
    margin: 15px -10px 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  &-cell {
    flex: 0 0 33.33%;
  }

  &-item {
    position: relative;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-family: $font-Comfortaa;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    background-color: #6e8791;
    color: #ffffff;
    transition: background-color 0.2s ease 0s, box-shadow 0.3s ease 0.2s;
    margin: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset;

    &.is-active {
      //background-color: rgba(#18ce76, 0.5);
      background-color: #18ce76;
    }

    &:hover {
      background-color: #18ce76;
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1) inset;
    }

    &.is-disabled {
      background-color: #6e6e6e;
      cursor: default;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }

    &.is-selected {
      background-color: #18ce76;
      cursor: default;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__count {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 110px;
    box-sizing: border-box;
    padding-top: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.14);
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  &__button {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: #617db4;

    &:hover {
      box-shadow: 0 0 18px 1px rgba(0, 0, 0, 0.3) inset;
    }

    &:active {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 1) inset,
        0 0 18px 1px rgba(0, 0, 0, 0.3) inset;
    }

    &--edit {
      top: 35px;
    }

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &-eye {
        width: 16px;
      }

      &-pencil {
        width: 14px;
      }
    }
  }
}

@keyframes animation-circle-loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.preset-group-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(#22a7f1, 0.1);
  z-index: 10;

  &__circle {
    width: 50px;
    height: 50px;
    display: block;
    background: transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border-left: 3px solid #22a7f1;
    visibility: visible;
    animation-iteration-count: infinite;
    animation-name: animation-circle-loader;
    animation-timing-function: linear;
    animation-duration: 1.5s;
    animation-delay: 0s;
  }
}

.preset-chains {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  column-gap: 10px;
  column-count: 3;
  width: calc(100% - 10px);

  &-holder {
    margin-top: 20px;
  }

  &__item {
    color: #ffffff;
    font-size: 13px;
    line-height: 18px;
  }

  &__not-found {
    color: #ffffff;
  }

  &-caption {
    color: #ffffff;
    position: relative;
    padding: 5px 35px 2px 15px;
    display: inline-block;
    border-radius: 50px;
    background-color: transparent;
    transition: 0.2s ease 0s;
    margin-top: 2px;
    cursor: pointer;

    &--selectable {
      cursor: pointer;

      &:hover {
        background-color: rgba(#18ce76, 0.7);
      }

      &.is-selected {
        pointer-events: none;
        background-color: #18ce76;

        &.is-selected-allow {
          pointer-events: all;
        }
      }

      &.is-highlighted {
        background-color: #18ce76;
      }

      &.is-disabled {
        pointer-events: none;
        color: #898989;
      }
    }

    &--edit {
      padding-top: 5px;
      padding-bottom: 5px;

      &.is-disabled {
        pointer-events: none;
        color: #898989;
      }

      &.is-selected {
        background-color: #18ce76;
        color: #ffffff;

        &.is-disabled {
          background-color: #848484;
          pointer-events: all;
        }
      }
    }

    &.is-view {
      color: #ffffff;
      cursor: default;
    }

    &__btn-remove {
      padding: 0;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) scale(0.5) rotate(0deg);
      width: 12px;
      height: 12px;
      opacity: 0;
      visibility: hidden;
      transition: transform 0.3s ease 0s, opacity 0.3s ease 0s,
        visibility 0.3s ease 0s;

      .preset-chains-caption--edit.is-selected & {
        transform: translateY(-50%) scale(1) rotate(360deg);
        opacity: 1;
        visibility: visible;
      }

      .icon {
        width: 12px;
        height: 12px;
        fill: #ffffff;
      }
    }
  }
}

.tooltip {
  position: absolute;
  border: 1px solid #e1e1e1;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
  min-width: 330px;
  z-index: 100;

  &-holder {
    position: relative;
  }

  &-header {
    background-color: #22a8f3;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-family: $font-Comfortaa;
    padding: 6px 10px;
  }

  &-content {
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
  }
}

.tooltip-question {
  border: 1px solid #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 8px;
  right: -2px;
  transform: translate(100%, -50%);
  top: 0;
  font-family: "Nunito", sans-serif;
  cursor: pointer;

  &-holder {
    position: relative;
  }

  &__content {
    position: absolute;
    bottom: 100%;
    border: 1px solid #e1e1e1;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-width: 330px;
    z-index: 100;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding: 10px;
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -4px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 6px 0;
      border-color: #fff transparent transparent;
    }
  }

  &:hover {
    .tooltip-question__content {
      transform: translateY(-10px);
      opacity: 1;
      visibility: visible;
    }
  }
}

.tooltip-distribution {
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: 360px;

  .gauge-linear-label {
    text-align: right;
    flex-basis: 170px;
  }

  .is-low-priority {
    background-color: #d9d9d9;
  }
}

.tooltip-base-size {
  border-left: 2px solid #f53b44;
  position: absolute;
  background: #3c4450;
  color: #ffffff;
  font-size: 12px;
  padding: 1px 7px;
  right: calc(100% + 7px);
  top: 50%;
  white-space: nowrap;
  transform: translateY(-50%);
  z-index: 100;
  font-family: "Nunito", sans-serif;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #3c4450;
  }
}

.tooltip-preset {
  background-color: #fcfcfc;
  width: 220px;
  position: fixed;
  box-shadow: 10px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 10;
  color: #000000;
  overflow: hidden;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0.3s;
  left: 5px;
  opacity: 0;
  visibility: hidden;

  &--bottom {
    top: 70px;
  }

  &--top {
    top: 15px;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &-chains {
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    max-height: 300px;
    position: relative;
    box-sizing: border-box;
    padding-right: 7px;
    margin: 15px 5px 10px 15px;

    &__item {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      align-items: center;

      &:hover {
        background: rgba(#000, 0.1);
      }

      &.is-disabled {
        pointer-events: none;
        color: #898989;
      }
    }
  }

  &-footer {
    background: #f5f5f5;
    font-size: 12px;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;

    &__buttons {
      .button-remove {
        margin-left: 5px;
      }
    }
  }
}

.nav-side-deep {
  list-style: none;
  padding: 0;
  overflow: hidden;
  transition: all 0s ease 0s;
  opacity: 0;
  max-height: 0;
  visibility: hidden;

  &__item {
    transition: opacity 0s ease 0s;

    &:first-child {
      margin-top: 15px;
    }
  }

  &__link {
    display: block;
    color: #ffffff;
    font-weight: 300;
    text-decoration: none;
    line-height: 24px;
    font-size: 13px;
    padding: 3px 0 0 18px;
    transition: background-color 0.3s cubic-bezier(1, 0, 0, 1) 0s;

    &:hover {
      background-color: #515361;
    }
  }
}

.nav-side {
  margin-top: 20px;
  margin-left: 30px;
  list-style: none;
  display: block;
  padding: 0;

  &__item {
    margin-top: 16px;

    &:hover {
      .nav-side-deep {
        max-height: 999px;
        opacity: 1;
        visibility: visible;
        transition: all 0.2s ease 0.2s;
      }

      .nav-side-deep__item {
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.6s;

        @for $i from 1 through 20 {
          $delay: $i * 0.08;
          &:nth-child(#{$i}) {
            transition-delay: #{$delay}s, 0.3s;
          }
        }
      }
    }
  }

  &-link {
    display: block;
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(209, 222, 227, 0.14);
    padding: 13px;
    transition: background-color 0.1s ease 0.3s;

    &:hover {
      background-color: #515361;
    }

    &__caption {
      display: inline-block;
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      user-select: none;
      padding-left: 45px;
    }

    &__icon {
      width: 18px;
      height: 18px;
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      fill: #ffffff;
    }
  }
}

.scroll-up {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
  top: 0;

  &__container {
    height: 100%;
    z-index: 100;
    position: relative;
  }

  &__button {
    background-color: #22a8f3;
    padding: 15px;
    border-radius: 3px;
    color: #ffffff;
    position: absolute;
    right: -15px;
    transform: translateX(100%) translateY(100%);
    pointer-events: all;
    bottom: 5%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease 0s;

    &.is-active {
      visibility: visible;
      transform: translateX(100%) translateY(0);
      opacity: 1;
    }
  }
}

.main-footer {
  &__copyright {
    padding: 30px 0 26px;
    text-align: center;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    color: #717171;
  }
}

.copyright {
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 12px;
  color: #cecece;
}

.not-found {
  &__box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top: -3%;
  }

  &__caption {
    font-size: 37px;
    line-height: 72px;
    font-weight: bold;
    color: #c2c2c2;
  }

  &__name {
    font-size: 186px;
    line-height: 186px;
    font-weight: bold;
    color: #c2c2c2;
    font-family: "Fjalla One", sans-serif;
  }

  &__btn {
    margin-top: 30px;
  }

  .cluster {
    margin-left: auto;
    margin-right: auto;
  }

  .main-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}

.react-tooltip {
  z-index: 1000;
}
