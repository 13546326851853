.main-header {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;

  &-container {
    background-color: #3c4450;
    position: relative;
    z-index: 1;
  }

  &__logo {
    flex-basis: 310px;
    align-self: center;
    position: relative;
    height: 100%;
  }

  &__navbar {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: stretch;
  }

  &__navigation {
    flex-direction: row;
    margin-right: auto;
  }

  &__search {
    flex-basis: 250px;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__dropdown {
    align-self: center;
  }
}

.main-logo {
  &__link {
    color: #ffffff;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding-right: 25px;
    margin-left: -2px;
    height: 100%;

    &:before {
      //content: "TM";
      display: inline-block;
      position: absolute;
      right: 6px;
      top: -10px;
      font-size: 10px;
      color: #c7c7c7;
    }
  }
}

.main-navigation {
  display: flex;
  background-color: #273242;
  align-items: stretch;
  border-left: 1px solid #515d70;

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    font: 700 16px/18px $font-Comfortaa;
    color: #ffffff;
    line-height: 100%;
    text-decoration: none;
    border-right: 1px solid #515d70;
    min-width: 150px;
    padding-left: 10px;
    padding-right: 10px;
    user-select: none;
    padding-top: 6px;

    &:first-child {
      padding-left: 10px;
      padding-right: 10px;
      min-width: auto;
      padding-top: 3px;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 2px);
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(#22a7f1, 1);
      transition: all 0.5s ease 0s;
      visibility: hidden;
      opacity: 0;
      transform: perspective(1000px) rotateX(-90deg) translateY(50px) scale(0.6);
    }

    &:not(.is-disabled):hover,
    &.is-selected {
      &:before {
        opacity: 1;
        visibility: visible;
        transform: perspective(1000px) rotateX(0deg) translateY(0) scale(1);
      }
    }

    &.is-disabled,
    &.is-selected {
      cursor: default;
    }

    &-disabled,
    &.is-disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__caption {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
  }

  &__icon {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;

    .icon-home {
      width: 26px;
      height: 24px;
    }
  }
}

.header-search {
  box-shadow: 0 3px 0 0 #22a8f3 inset;

  &__input {
    position: relative;
    margin-top: 16px;

    .icon-search {
      fill: #22a8f3;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.dropdown-navigation {
  position: relative;
  height: 50px;
  width: 50px;
  cursor: pointer;

  &-item {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 0;
    top: -10px;
    background-color: transparent;
    transform: translateY(0);

    &:nth-of-type(1) {
      z-index: 101;
      transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.01s;
    }

    &:nth-of-type(2) {
      z-index: 100;
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 15ms;
    }

    &:nth-of-type(3) {
      z-index: 99;
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 30ms;
    }

    &:nth-of-type(4) {
      z-index: 98;
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 45ms;
    }

    .icon {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      transition: none;
    }

    &__link {
      position: relative;
      top: 10px;
      display: block;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      background-color: #32c1e9;
      float: right;
      transition: width 0.25s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
      color: #ffffff;
      text-decoration: none;
      //box-shadow: 0 0 0 5px #c7c7c7 inset;
      &:hover {
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

        > .icon {
          transform: translateY(-50%) rotate(360deg);
          transition: all 0.4s ease 0.1s;
        }
      }
    }

    &__caption {
      font-size: 14px;
      position: absolute;
      right: 45px;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      transition: all 0.1s ease 0s;
      opacity: 0;
      visibility: hidden;

      .dropdown-navigation-item__link:hover & {
        opacity: 1;
        visibility: visible;
        transition: all 0.1s ease 0.2s;
      }
    }
  }

  &:hover {
    .dropdown-navigation-item {
      width: 160px;
      @for $i from 1 through 4 {
        $translateY: $i * 60;
        &:nth-of-type(#{$i}) {
          transform: translateY(#{$translateY}px);
        }
      }
    }
  }

  &-initials {
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background: #c7c7c7;
    font-size: 22px;
    color: #ffffff;
    font-weight: normal;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    z-index: 10;
    backface-visibility: hidden;
  }
}

.dropdown-navigation-indicator {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 10px;
  width: 10px;
  background: #f53b44;
  z-index: 110;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
}

.main-nav-tooltip {
  position: absolute;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  transition: opacity 0.3s ease 0s;
  left: 50%;
  transform: translateX(-50%);
  $root: &;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 4px;
  }

  &.is-left-aligned {
    left: 0;
    transform: translateX(40px);
  }

  .main-navigation__item:hover & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease 0s;
  }

  &__content {
    display: block;
    color: #ffffff;
    background-color: #333333;
    font-size: 12px;
    line-height: 17px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    width: 275px;
    margin-bottom: 10px;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 4px;
      border-color: transparent transparent #333333;
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);

      #{$root}.is-left-aligned & {
        left: 25px;
        transform: translateX(0);
      }
    }
  }
}
