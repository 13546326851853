@import "../../../styles/_mixines";

.table-menu-demo,
.table-menu-chains {
  table-layout: fixed;

  .cell--group {
    text-align: left;
    padding-left: 20px;
    background: #72767c;
    font-size: 16px;
    color: #ffffff;
    padding-top: 12px;
    padding-bottom: 8px;
    border-bottom: none;
  }

  .cell--menu-category {
    width: 340px;
    text-align: left;
    padding-left: 20px;
  }

  .cell--name-current-chain {
    background-color: rgba(#22a8f3, 0.1);
  }
}

.tooltip-frequency {
  padding: 15px;
  right: 15px;
  top: -15px;
  transform: translateY(-100%);
  background-color: #fff;
  text-transform: initial;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  min-width: 260px;
  text-align: left;
  overflow: initial;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;

  &:after {
    content: "";
    position: absolute;
    right: 10px;
    transform: translateX(-50%);
    bottom: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 0;
    border-color: #fff transparent transparent;
  }

  &__abbreviation {
    font-weight: 700;
    color: #000000;
  }

  &__header {
    font-weight: 700;
    color: #000000;
    margin-bottom: 5px;
  }
}

.table-menu-demo {
  .table-head {
    .cell {
      &:hover {
        .tooltip-frequency {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.card-menu {
  padding: 10px 30px 20px;

  &__list {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: -15px;
    margin-right: -15px;

    &:last-child {
      margin-top: 20px;

      &:before {
        content: "";
        display: block;
        background-color: #e8e8e8;
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
      }
    }
  }
}

.card-menu-category {
  flex: 0 0 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-sizing: border-box;

  &__caption {
    box-sizing: border-box;
    font-family: "Comfortaa", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #3c4450;
    border-bottom: 1px solid #3c4450;
    padding: 5px 15px 7px;
  }
}

.card-menu-food {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3c4450;
    box-sizing: border-box;
    font-size: 14px;
    border-bottom: 1px solid #e8e8e8;
    padding: 12px 15px;
    line-height: 16px;
    background-color: transparent;
    transition: 0.3s ease 0s;
    cursor: pointer;

    &:hover {
      background-color: rgba(#22a8f3, 0.9);
      color: #ffffff;
    }
  }

  &__caption {
    display: block;
    font-size: 14px;
    line-height: 18px;
  }

  &__value {
    box-sizing: border-box;
    display: block;
    white-space: nowrap;
    padding-left: 10px;
  }
}

.modal-menu-category-header {
  padding: 0 35px;
  text-transform: uppercase;
  margin-top: 30px;

  &__item {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  &__copy-button {
    position: absolute;
    font-size: 14px;
    color: #aeaeae;
    right: 54px;
    top: 20px;
    border-bottom: 1px solid transparent;
    text-transform: lowercase;

    &:hover {
      border-bottom: 1px solid rgb(204, 204, 204);
    }
  }
}

.gauge-columns-container {
  padding-bottom: 40px;
}

.gauge-columns-grid {
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #eeeeee;
}

.gauge-columns-group {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
}

.gauge-columns-bar {
  width: 40px;
  background: linear-gradient(#ed27df 0%, #ff0000 100%);
  height: 0%;
  margin-left: 9px;
  margin-right: 9px;
  color: #3c4450;
  position: relative;

  &__value {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    transform: translateY(-100%);
    position: absolute;
    width: 100%;
    padding-bottom: 3px;
  }

  &__label {
    display: block;
    position: absolute;
    bottom: -10px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    transform: translateY(100%) translateX(-50%);
    left: 50%;
    white-space: nowrap;
  }
}
